import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/Layout";
import phonesCentre from "../img/phones-all.png";
import phonesMobile from "../img/home-main-mobile.png"
import phoneFeed from "../img/phone-feed.png";
import phoneCalendar from "../img/phone-calendar.png";
import phoneChats from "../img/phone-chats.png";
import phonePost from "../img/phone-post.png";
import newsletterImage from "../img/newsletter_image.png";
import plantsRed1 from "../img/plants-red-1.png";
import plantMobileRed from "../img/plant-mobile-red.png";
import plantMobileGreen from "../img/plant-mobile-green.png";
import plantsRed2 from "../img/plants-red-2.png";
import plantsYellow from "../img/plants-yellow-1.png";
import plantGreen from "../img/plant-green-1.png";
import twitter from "../img/social_twitter_red.png";
import discord from "../img/social_discord_red.png";
import facebook from "../img/social_facebook_red.svg";
import googlePlay from "../img/play_store.svg";
import appStore from "../img/app_store.svg";
import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

 

// eslint-disable-next-line
export class IndexPageTemplate extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  
  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
  return (
    <div>
      <section className="section dark-background-section main-section">
      <img src={phonesCentre} style={{position: "absolute"}} className="is-hidden-mobile" />
        <div className="home-circle" style={{display: "flex"}}> 
          <div className="home-circle-container is-hidden-mobile has-text-centered" >
            <h1>Small steps to <br /><b>recovery</b></h1>
              <div className="row" style={{marginTop: "5vh", zIndex: "20"}}>
                <a href="https://play.google.com/store/apps/details?id=com.oad.oneaday&hl=en_GB&gl=US"><img style={{width: "180px", marginRight: "1rem"}} src={googlePlay} /></a> 
                <a href="https://apps.apple.com/app/one-a-day-gratitude-platform/id1662212197"><img style={{width: "180px"}} src={appStore} /></a> 
              </div>
          </div>
          <img src={phonesMobile} className="only-mobile" style={{width: "80vw"}} />
        </div>
        <div className="content has-text-centered only-mobile" style={{marginTop: "4rem"}} >
            <h2 style={{fontSize: "2.5rem"}}>Small steps to <b>recovery</b></h2>
            <div className="subtext-container">
              <div className="row">
              <a href="https://play.google.com/store/apps/details?id=com.oad.oneaday&hl=en_GB&gl=US"><img style={{width: "180px", marginRight: "1rem"}} src={googlePlay} /></a>
              <a href="https://apps.apple.com/app/one-a-day-gratitude-platform/id1662212197"><img style={{width: "180px"}} src={appStore} /></a> 
              </div>
            
            </div>
          </div>
      </section>
      <section className="section section--gradient only-desktop-margin-top">
        <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-6" style={{display: "flex"}}>
                      <div className="content">
                        <h1 className="title second-section-title margin-top-1-vh-tablet mobile-margin-right">
                          <span style={{color: "#FFC143"}}>One A Day</span> is a positivity journaling platform, for people dealing with mental health issues.
                        </h1>
                        <img src={plantMobileRed} className="only-mobile" style={{position: "relative", float: "right", marginRight:"-1.8rem", top: "-6rem"}}  />

                        <a href="https://play.google.com/store/apps/details?id=com.oad.oneaday&hl=en_GB&gl=US" className="button" style={{marginTop: "5vh", zIndex: "10"}}>Download for Android</a>
                        <a href="https://apps.apple.com/app/one-a-day-gratitude-platform/id1662212197" className="button" style={{marginTop: "5vh", zIndex: "10"}}>Download for iOS</a>
                        <div style={{position: "absolute", left: "4rem", bottom: "-4rem"}}>
                        <img src={plantsRed1}  className="plant-20 is-hidden-mobile" />
                        </div>
                      </div>
                    </div>
                    <div className="column is-6">
                      <img src={phoneFeed} style={{width: "80%", marginLeft: "20%"}} className="home-page-phone" />
                    </div>
                  </div>
                </div>
                  </div>
                </div>
              </div>
      </section>
      <section>
        <div className="section dark-background-section">
          <div className="columns">
            <div className="column is-6 is-hidden-mobile" style={{marginLeft: "-3rem"}}>
              <img src={phonePost} style={{width: "80%"}}  />
            </div>
            <div className="column is-6" style={{display: "flex", position: "relative"}}>
            <img src={plantGreen} className="is-hidden-mobile is-hidden-tablet" style={{position: "absolute", right: "2rem", top: "-3rem"}}  />
            <div className="content vertical-center">
            <img src={plantMobileGreen} className="only-mobile" style={{position: "absolute", left: "0.5rem", top: "-2.5rem", width: "10vw"}}  />

              <h1 className="title margin-none-mobile margin-left-mobile" >
                  Share what you are <span style={{color: "#FFC143"}}>grateful</span> for each day. No matter how small it is.                      </h1>
            </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                  <div className="column is-6" style={{padding: "2vw 4vw 2vw 0vw"}}>
                      <img src={phoneCalendar} style={{width: "80%"}} className="home-page-phone" />
                    </div>
                    <div className="column is-6" style={{display: "flex"}}>
                    <div className="content" >
                      <div className="containter">
                      <h1 className="title margin-top-20-vh margin-none-mobile margin-top-1-vh-tablet">
                      Start with just one positive thought a day and build up a <span style={{color: "#FFC143"}}>journal</span> to get you through difficult times.
                      </h1>
                      <a href="https://play.google.com/store/apps/details?id=com.oad.oneaday&hl=en_GB&gl=US" className="button" style={{marginTop: "5vh", zIndex:"10"}}>Download for Android</a>
                      <a href="https://apps.apple.com/app/one-a-day-gratitude-platform/id1662212197" className="button" style={{marginTop: "5vh", zIndex: "10"}}>Download for iOS</a>
                      </div>
                      <div style={{position: "absolute", right: "2rem", bottom: "-4rem"}}>
                        <img src={plantsYellow} className="plant-20"   />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                </div>
              </div>
      </section>
      <section>
        <div className="section dark-background-section">
          <div className="columns">
            <div className="column is-10 is-offset-1" style={{display: "flex"}}>
            <div className="content vertical-center">
              <h1 className="title" style={{marginBottom: "0px", marginTop: "-0.2em"}}>
                  Join our <span style={{color: "#FFC143"}}> community! </span> Follow us on:   
               
              <a title="twitter" href="https://twitter.com/one_a_day_app">
                  <img
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em", margin: "0.2em 0.6em -0.1em 0.5em" }}
                  />
                </a>
                <a title="discord" href="https://discord.gg/pSWmkRanfG">
                  <img
                    src={discord}
                    alt="Discord"
                    style={{ width: "1em", height: "1em",  margin: "0.2em 0.6em -0.1em 0.0em" }}
                  />
                </a>
                <a title="facebook" href="https://www.facebook.com/profile.php?id=100069096422186">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em",  margin: "0.2em 0em -0.1em 0em" }}
                  />
                </a>
                </h1>
                </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns reverse-column">
                    <div className="column is-6" style={{display: "flex"}}>
                      <div className="content">
                        <h1 className="title margin-top-20-vh margin-top-none-mobile margin-top-1-vh-tablet">
                        You’re <span style={{color: "#FFC143"}}>not alone.</span> Make friends with people who know what you’re going through.
                        </h1>
                        <a href="https://play.google.com/store/apps/details?id=com.oad.oneaday&hl=en_GB&gl=US" className="button" style={{marginTop: "5vh", zIndex: 10}}>Download for Android</a>
                        <a href="https://apps.apple.com/app/one-a-day-gratitude-platform/id1662212197" className="button" style={{marginTop: "5vh", zIndex: "10"}}>Download for iOS</a>
                        <div className="plant-mobile-right" style={{position: "absolute", left: "2rem", bottom: "-4rem"}}>
                        <img src={plantsRed2} className="plant-20" />
                        </div>
                      </div>
                    </div>
                    <div className="column is-6">
                      <img src={phoneChats} style={{width: "80%", marginLeft: "20%"}} className="home-page-phone" />
                    </div>
                  </div>
                </div>
                  </div>
                </div>
              </div>
      </section>
      <section>
        <div className="section dark-background-section">
          <div className="columns">
            <div className="column is-6 is-offset-1" style={{display: "flex"}}>
              <div className="content vertical-center" id="beta" >
                <div>
                <p style={{fontWeight: "600", letterSpacing: "3px"}}>JOIN OUR</p>
              <h1 className="title newsletter-title" >
                  NEWSLETTER
              </h1>
              <p style={{fontSize: "1.3rem", fontWeight: "500"}}>Be the first one to know about the iOS version and get regular updates about our progress!</p>
              <p style={{fontSize: "1.3rem"}}><b>Subscribe now!</b></p>
                </div>
              </div>
            </div>
            <div className="column is-6">
              <img src={newsletterImage} />
            </div>
          </div>
          <form
                name="newsletter"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
          <div className="columns">
            <div className="column is-8 is-offset-1">
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
            <input type={"email"}
                      name={"email"}
                      onChange={this.handleChange}
                      id={"email"}
                      required={true} placeholder="Enter email address" className="input" />
            <p className="textfield-note">Your email is safe with us, we don't spam</p>
            </div>
            <div className="column is-3">
            <button type="submit" className="button" style={{width: "66%"}}>Sign up</button>
            </div>
            </div>
            </form>
        </div>
      </section>
    </div>
  );
  }
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <IndexPageTemplate
      />
    </Layout>
  );
};

export default IndexPage;